import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"

const ProductTemplate = ({
  data: {
    product: {
      name,
      price,
      descriptionNode: {
        childMarkdownRemark: { html },
      },
      image,
    },
  },
}) => {
  return (
    <>
      <Helmet title={name}></Helmet>
      <Layout>
        <Container>
          <Inner>
            <Image fluid={image.fluid} />
            <Properties>
              <h1>{name}</h1>
              <Description
                dangerouslySetInnerHTML={{ __html: html }}
              ></Description>
              <h3>
                {price} <span>BYN*</span>
              </h3>
              <Notice>
                <span role="img" aria-label="money">
                  💰
                </span>
                Обязательно уточняйте наличие и цену.
                <br></br>
                <span role="img" aria-label="car">
                  🚚
                </span>
                Для заказа и доставки
                <Link to="/contact"> cвяжитесь с нами</Link> любым удобным
                способом.
                <br></br>
                <span role="img" aria-label="eye">
                  👁
                </span>
                У нас есть
                <Link to="/contact"> магазин</Link> где все можно посмотреть в
                живую.
              </Notice>
            </Properties>
          </Inner>
        </Container>
      </Layout>
    </>
  )
}

export default ProductTemplate

export const query = graphql`
  query($id: String!) {
    product: datoCmsProduct(id: { eq: $id }) {
      name
      price
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(
          maxWidth: 600
          imgixParams: { fm: "jpg", auto: "compress, format" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`

const Inner = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;

  margin-bottom: 40px;
`

const Image = styled(Img)`
  height: 100%;
  width: 100%;

  flex-basis: 50%;
  border-radius: 6px;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 100%;
  }
`

const Properties = styled.div`
  flex-basis: 45%;

  h1 {
    color: ${({ theme }) => theme.colors.contrast};
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 40px;

    @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
      margin-top: 32px;
    }
  }

  h3 {
    font-weight: 900;
    font-size: 32px;
  }

  span {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 100%;
  }
`

const Description = styled.article`
  line-height: 140%;
`

const Notice = styled.div`
  font-size: 14px;
  line-height: 160%;
  color: #828c9f;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`
